<template>
  <div>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <el-button
                class="ml-15"
                type="primary"
                @click="showAdDialog('add')"
                >新增广告</el-button
              >
            </div>
          </div>

          <!-- 门店列表 -->
          <el-tabs v-model="storeId" @tab-click="changeTab">
            <el-tab-pane
              :label="item.storeName"
              :name="item.id + ''"
              v-for="(item, index) in shopList"
              :key="index"
            >
            </el-tab-pane>
          </el-tabs>

          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="标题" prop="title"></el-table-column>
            <el-table-column label="图片" prop="picture">
              <template slot-scope="scope">
                <el-avatar
                  :src="scope.row.picture"
                  shape="square"
                  alt="加载失败"
                  :key="Math.random()"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column
              label="创建时间"
              prop="createTime"
            ></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="上架"
                  inactive-text="下架"
                  @change="upDownShelf($event, scope.row.id)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <span
                    @click="showAdDialog('edit', scope.row.id)"
                    class="tip-text-edit"
                  >
                    <i class="el-icon-edit-outline"></i>
                    编辑
                  </span>
                  <span
                    class="ml-10 tip-text-delete"
                    @click="deleteAd(scope.row.id)"
                  >
                    <i class="el-icon-delete"></i>
                    删除
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="search"
            @syncPageData="syncPageData"
          ></Pagination>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="showAd"
      :title="opeType == 'add' ? '添加广告' : '编辑广告'"
      @close="clearDialog"
    >
      <div class="pad-container">
        <el-form
          class="mt-24"
          label-width="100px"
          :model="popup_form"
          :rules="rules"
          ref="popup_form"
        >
          <el-form-item label="标题" prop="title">
            <el-input
              placeholder="请输入标题内容"
              v-model="popup_form.title"
              class="w-100"
            ></el-input>
          </el-form-item>
          <el-form-item label="选择门店" prop="storeId">
            <el-select
              placeholder="选择所属门店"
              v-model="popup_form.storeId"
              class="w-100"
            >
              <el-option
                v-for="(item, index) in shopList"
                :key="index"
                :value="item.id"
                :label="item.storeName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="添加图片" prop="picture">
            <UploadPics
              :uploadType="'main'"
              :imgLimit="1"
              :imgSize="'300*400'"
              :imgsPreview="popup_form.picture"
              @getImgData="getImgData"
            />
          </el-form-item>
          <el-form-item label="跳转链接" prop="turnUrl">
            <el-input
              placeholder="请输入跳转链接"
              v-model="popup_form.turnUrl"
              class="w-100"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="flx-row ali-c js-fe mt-50">
          <el-button
            plain
            size="small"
            class="form-button-w70"
            @click="showAd = false"
            >取消</el-button
          >
          <el-button
            class="form-button-w70"
            size="small"
            type="primary"
            @click="submit"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import UploadPics from "@/components/UploadPics";
import request from "@/utils/request";
import { getStoreList } from "@/api/online_department_store/commodity_manage";
import {
  getAdList,
  getPopupsType,
  adUpDownShelf,
  subAdForm,
  getAdDetail,
  adDelete,
} from "@/api/config_center/miniprogram_popup";
export default {
  components: {
    Pagination,
    UploadPics,
  },
  data() {
    return {
      // 顶部搜索栏数据
      keyword: "",
      storeId: "1",
      shopList: [],
      // 会员表格数据内容
      tableData: [],
      showAd: false,
      popup_form: {
        title: "",
        picture: "",
        turnUrl: "",
        storeId: "",
      },
      rules: {
        title: [
          { required: true, message: "标题内容不能为空", trigger: "blur" },
        ],
        picture: [{ required: true, message: "图片不能为空" }],
        storeId: [
          { required: true, message: "选择门店不能为空", trigger: "blur" },
        ],
      },
      couponsList: [],
      setPopup: false,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      opeType: "",
    };
  },
  mounted() {
    this.getStores();
  },
  methods: {
    // 搜索事件
    search(type) {
      if (type == 1) {
        this.pageNum = 1;
        this.pageSize = 10;
      }
      let params = {
        storeId: this.storeId,
        // keyword: this.keyword,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      getAdList(params).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.body.total;
          this.tableData = res.data.body.list;
          this.tableData.map((item) => {
            item.picture = this.picsPath(item.picture);
          });
        }
      });
    },
    // 获取门店列表
    getStores() {
      getStoreList()
        .then((res) => {
          this.shopList = res.data.body.list;
          this.storeId = this.shopList[0].id.toString();
          this.search(1);
        })
        .catch((err) => {
          this.search(1);
        });
    },
    // 重置搜索关键词
    reset() {
      this.storeId = "1";
      this.keyword = "";
      this.search(1);
    },
    clearDialog() {
      this.popup_form = {
        title: "",
        picture: "",
        turnUrl: "",
        storeId: "",
      };
    },
    //同步子组件修改的数据
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    //上下架
    upDownShelf(e, id) {
      adUpDownShelf(id, e).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
        }
      });
    },
    // 门店改变
    changeTab(tab, event) {
      this.storeId = tab.name;
      this.search(1);
    },
    deleteAd(id) {
      //删除广告
      this.$confirm("此操作将永久删除该广告, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          adDelete(id).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.search();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //获取单个广告
    getAd(id) {
      getAdDetail(id).then((res) => {
        if (res.data.code == 200) {
          this.popup_form = res.data.body;
          this.picList = [];
          this.picList.push({
            name: "",
            url: this.picsPath(res.data.body.picture),
          });
        }
      });
    },
    showAdDialog(type, id) {
      this.showAd = true;
      this.opeType = type;
      if (id != undefined && id != "undefined") {
        this.getAd(id);
      }
    },
    getImgData(imgData, type) {
      if (type == "main") this.popup_form.picture = imgData.mainImg;
    },
    submit() {
      this.$refs["popup_form"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
          return;
        }
        this.submitForm();
      });
    },
    submitForm() {
      let data = this.popup_form;
      //   data.storeId = this.storeId
      subAdForm(data, this.opeType == "edit" ? 1 : 0).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.showAd = false;
          this.clearDialog();
          this.search();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text-edit {
  color: #409eff;
  cursor: pointer;
}
</style>
