import request from '@/utils/request'

// 获取广告列表
export function getAdList (data) {
    return request({
        url: '/customer-service/lrPopUpWindows/queryPopUpWindows',
        method: 'post',
        data
    })
}

//获取弹窗类型
export function getPopupsType () {
    return request({
        url: '/small-inteface/user-service/popWindow/couponShowType',
        method: 'get'
    })
}

// 广告上下架
export function adUpDownShelf (id,e) {
    return request({
        url:  `/customer-service/lrPopUpWindows/upAndDowndPopUpWindows?id=${id}&status=${e}`,
        method: 'post'
    })
}

// 新增、编辑广告信息
export function subAdForm (data, type) { //type 0 新增 1 编辑
    return request({
        url: type == 1 ? '/customer-service/lrPopUpWindows/updatePopUpWindows' : '/customer-service/lrPopUpWindows/addPopUpWindows',
        method: 'post',
        data
    })
}

// 根据id获取广告详情
export function getAdDetail (id) {
    return request({
        url:  `/customer-service/lrPopUpWindows/queryPopUpWindowsDetail?id=${id}`,
        method: 'get'
    })
}

// 删除广告
export function adDelete (id) {
    return request({
        url:  `/customer-service/lrPopUpWindows/delPopUpWindows?id=${id}`,
        method: 'delete'
    })
}
// ********************首页弹窗模块********************
// 接口：删除新人礼包 delete
// 地址：http://${BASE_URL}/customer-service/lrNewUserCoupon/delete?id=#{id}
export function lrNewUserCouponDelete (id) {
    return request({
        url:  `/customer-service/lrNewUserCoupon/delete?id=${id}`,
        method: 'delete'
    })
}

// 接口：查看开关（新人礼包） get
// 地址：http://${BASE_URL}/customer-service/lrNewUserCoupon/getTurn
//  0关 1开
export function getTurn () {
    return request({
        url:  `/customer-service/lrNewUserCoupon/getTurn`,
        method: 'get'
    })
}

// 接口：操作开关（新人礼包） post
// 地址：http://${BASE_URL}/customer-service/lrNewUserCoupon/operationTurn?isTurn=#{isTurn}
export function operationTurn (isTurn) {
    return request({
        url:  `/customer-service/lrNewUserCoupon/operationTurn?isTurn=${isTurn}`,
        method: 'post'
    })
}

// 接口：获取新人礼包 get
// 地址：http://${BASE_URL}/customer-service/lrNewUserCoupon/queryLrNewUserCoupon
export function queryLrNewUserCoupon () {
    return request({
        url:  `/customer-service/lrNewUserCoupon/queryLrNewUserCoupon`,
        method: 'get'
    })
}

// 接口：保存新人礼包 post
// 地址：http://${BASE_URL}/customer-service/lrNewUserCoupon/saveLrNewUserCoupon
// couponIds	string	true	普通参数		优惠券id
export function saveLrNewUserCoupon (data) {
    return request({
        url: '/customer-service/lrNewUserCoupon/saveLrNewUserCoupon',
        method: 'post',
        data
    })
}